import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const CombingTips = () => (
  <Layout>
    <SEO
      title="Careful Combing Tips For Natural Hair"
      desciption="One of the most common complaints I see is about edges. They are definitely under-appreciated by many people because they think they will be there forever"
    />
    <h1>Careful Combing Tips For Natural Hair</h1>

    <p>We all know that the combing process is to detangle your hair and for general maintenance or so it can be styled more easily. It seems so simple but there is more to it than you might have thought. Your combing or detangling routine can make all the difference to the health of your hair and how long it gets.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/HIebfmz.jpg"
        alt="Careful Combing Tips For Natural Hair"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Photo credit: <a
        href="https://unsplash.com/@rangokonk"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >Raghavendra V. Konkathi</a></p>
    </div>

    <p>We all know that the combing process is to detangle your hair and for general maintenance or so it can be styled more easily. It seems so simple but there is more to it than you might have thought. Your combing or detangling routine can make all the difference to the health of your hair and how long it gets.</p>
    <p>A good combing routine should be pain free and not something you dread. You shouldn’t have to pull the comb quick and hard just to get it through. If you have to do this you will be detangling your hair but you will also be causing massive stress on your hair by tearing it out, breaking it and putting a strain on your scalp.</p>
    <p>Here are some tips to get you through and make it easier;</p>

    <h2>Comb at Each Stage</h2>
    <p>What I mean by this is for each stage in your hair care routine, you can comb your hair to keep it tangle free. If you have very curly hair, you might have experienced your hair somehow becoming more tangled and harder to comb as you go through your process. To help reduce stress you can do a little at each step which will add up and make for less work at the end.</p>

    <h2>Use a Wide Tooth Comb</h2>
    <p>The spacing of the teeth in the comb matters a whole lot. The wider the teeth, the less stress you put on tangled hair while you are combing it. Start with a wide tooth comb if you have lots of knots. Then move down to a comb with smaller gaps when you are happy you’ve done enough. You might only need two combs so it’s not like you need to keep a big collection. I’m sure most of you already have a wide tooth comb but it’s easy to forget to use it and just rush through the job of detangling to get it done.</p>
    <p>If you don’t have a wide tooth comb then I am genuinely surprised and disappointed and I urge you to get one now. It will be one of the best purchases you’ve made in a long time.</p>
    <p>The process of using the wide tooth comb is the same as any other. Start at the tips and work your way down to the roots until the knots are gone or at least manageable for the next stage. Something you can do with a wide tooth comb that you might not be able to do with a regular one, depending on the length of your hair is this; drag the comb from front to back touching your scalp the whole time.</p>
    <p> If you have longer hair, you know how difficult this can be with a narrower tooth comb. It’s not an achievement and nothing to be proud of but it’s one of the most satisfying things in the hair care routine, for me at least.</p>

    <h2>Finger Comb First</h2>
    <p>Finger combing is a good way to start the process because you can actually feel the knots for yourself and your fingers are a great natural wide tooth comb. After you’ve wet your hair and applied the shampoo, use the added slip to help your fingers guide through the knots and break them up. You won’t get all of them but it will be a really good start.</p>
    <p>The reason you need to do this with a shampoo or some sort of pre conditioner is the added slip I mentioned. Water just doesn’t have the properties and while it changes the feel of your hair, it actually makes it more prone to damage so it can break more easily if you apply stress.</p>
    <p>Finger combing can be used at the conditioning stage too. If you haven’t tried it before because it didn’t cross your mind or seemed like a waste of time then you might be pleasantly surprised at the results you get.</p>

    <h2>Use a Detangler</h2>
    <p>Using a detangle is the method that most people use. Even if you don’t have a detangle specifically, most leave-in conditioners have detangling properties anyway. After you apply evenly from the roots to the tips of your hair, the combing process should become a whole lot easier.</p>
    <p>Divide your hair into small sections and apply detangle as you go, then when do the comb should glide straight through. When it does get stuck, a few gentle tugs should be enough to remove the knots. Then you can move onto the next section and before you know it, you’ve done your whole head.</p>


    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}



      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>  
        <p>
            <Link to="/14-tips-on-how-to-grow-afro-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            14 tips on how to grow afro hair
            </Link>{" "}
            - Growing afro hair can be one of the most annoying things in the world, the good news is that growing natural doesn't have to be hard. Read our 14 tips to make your life easier here...
          </p>             
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default CombingTips
